// Styling the ProjectContainer component

// Styling the outer container
.project-container {
  width: 100%;
  margin: 8px 0;
  background-color: rgb(230, 230, 230);
  min-height: 200px;
  padding: 20px;
  border: 1px solid transparent;
  border-radius: 12px;

  // Styling the inner container
  .inner-project-container {
    display: grid;

    // Styling the tag list
    .tag-list {
      display: flex;
      flex-direction: row;

      // Styling individual tags
      .tag {
        margin: 0 5px;
        p {
          margin: 0;
          padding: 3px 12px;
          background-color: aliceblue;
          border-radius: 1000px;
          font-size: 14px;
          text-transform: capitalize;
        }
        &:first-child {
          margin-left: 0px !important;
        }
      }

      // background colors for different tags
      .external p {
        background-color: rgb(44, 90, 240);
        color: white;
      }

      .internal p {
        background-color: blueviolet;
        color: white;
      }

      .project p {
        background-color: rgb(243, 243, 203);
      }

      .web p {
        background-color: forestgreen;
        color: white;
      }
      .open p {
        background-color: crimson;
        color: white;
      }
    }

    // Project info
    .project-info {
      // text-box div
      .text-box {
        margin: 10px 0;
        h5 {
          margin: 0;
          font-size: 15px;
        }
        .span-text {
          font-size: 15px;
        }
      }
    }
  }
}
