// @desc: Styling the dashboard page

#Dashboard {
  width: 100vw;
  background: #f8f8f8;
  min-height: calc(100vh - 150px);

  width: 90%;
  max-width: 1440px;
  margin: 0 auto;

  .dash-inner-container {
    margin-top: 40px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      'greeting greeting time'
      'hours completedTasks allTasks';
    gap: 15px;
    h3 {
      grid-area: greeting;
    }

    .time {
        grid-area: time;
        color: #333;
        display: flex;;
        justify-content: right;
    }

    .dash-info-box {
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 8px;

    }
    .hours-worked {
      grid-area: hours;
      background: rgb(191, 236, 191);
    }

    .completedTasks {
        background: rgb(233, 233, 184);
    }
    .allTasks {
        background: rgb(212, 238, 241);
    }
  }
}
