// Styles the navigation component that is visible before logging in


#NoLogNav {
    width: 100vw;
    position: fixed;
    bottom: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 10;
    color: white;
    
    // Styling the inner container for the navigation
    // that contains the links
    .inner-container {
        display: flex;
        flex-direction: row;
        background-image: linear-gradient(231.49deg, rgb(71, 76, 104) 9.16%, rgb(43, 46, 64) 96.7%), linear-gradient(231.49deg, rgb(35, 38, 47) 9.16%, rgb(35, 38, 47) 96.7%);
        padding: 20px;
        border-radius: 1000px;;
        
        // Styling the navigation link
        .nav-link {
            font-size: 1.1rem;
            margin: 0 18px;

            &:hover {
                cursor: pointer;
                
            }
            &.active {
                background: white;
                color:rgb(71, 76, 104);
            }
        }
    }
}