// styling the profile page

#Profile {
    width: 100%;

    // Styling the banner
    .profile-banner {
        width: 100%;
        max-width: 100%;

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }

    // Styling the avatar
    .avatar-container {
        position: absolute;
        top: 250px;
        width: 200px;
        height: 200px;
        left: 10%;
        border-radius: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        img {
            padding: 5px;
            object-fit: contain;
            width: 200px;
            height: 200px;
            background-color: white;;
            border-radius: 1000px;
        }
    }
}