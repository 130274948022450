// This file styles the under development notice

.viewer-notice {
  width: 100vw;
  height: 100vh;

  background: hsla(0, 11%, 2%, 0.734);
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow: hidden;
  -o-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  // Styling the content-container
  .inner-container {
    width: 80%;
    max-width: 800px;
    // min-height: 400px;
    background: #fff;
    color: hsla(0, 5%, 4%, 0.763);
    text-align: center;
    padding: 60px;
    z-index: 100;
    border-radius: 20px;

    // Styling action button
    .accepted {
      padding: 12px 18px;
      border-radius: 12px;
      width: 180px;
      background: linear-gradient(45deg, #e66465, #9198e5);
      color: white;
      border: 1px solid transparent;
    
      &:hover {
        cursor: pointer;
        background: linear-gradient(-45deg, #e66465, #9198e5);
      }
    }
  }
}
