// This file styles the homepage

// Import font
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// Styling the outer layer of the homepage
#HomePage {
  background-color: #101220;
  min-height: 100vh;
  color: aliceblue;
  font-family: "Poppins", sans-serif;
  contain: content;

  // Styling the Header section of the homepage
  header {
    width: 100vw;
    .header-container {
      padding: 20px 40px;
      display: flex;
      flex-direction: row;
      align-items: center;

      // Logos at the top middle of the page
      .logos {
        overflow: hidden;
        width: 320px;
        contain: content;
        margin: 0 auto;
        margin-top: -20px;

        // Styling the images themselves
        img {
          width: 45%;
          padding-top: 12px;
        }

        // Tuas logo
        .tuas-logo {
          padding-right: 5%;
        }

        // theFIRMA logo
        .theFIRMA-logo {
          padding-left: 5%;
        }
      }
      // Styling the login button in the header
      .login__btn-container {
        button.login__btn {
          text-align: center;
          padding: 8px 0;
          background: linear-gradient(
            230.36deg,
            #ee5ef1 -0.13%,
            #b006b4 89.88%
          );
          color: white;
          font-weight: 500;
          width: 100px;
          border-radius: 1000px;
          border: 1px solid transparent;
          &:hover {
            background: linear-gradient(
              230.36deg,
              #ee5ef1 -0.13%,
              #b006b4 91.88%
            );
          }
        }
      }
    }
  }

  // Styling main section of the homepage
  .hero-container {
    text-align: center;
    width: 90%;
    max-width: 65ch;
    margin: 0 auto;
    margin-top: 120px;

    // styling the h1 tag
    h1 {
      // Styling the "Linking" word on the hero section
      span {
        background: -webkit-linear-gradient(
          350deg,
          #00d2e9 -4.81%,
          #e975e8 81.46%
        );
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      font-size: 4.8rem;
    }

    // Styling the subtext under the hero header
    p {
      margin-top: 40px;
      color: rgba(255, 255, 255, 0.6);
      line-height: 1.8;
    }

    // Call to action container
    .action-buttons {
      margin-top: 40px;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;

      // styling the buttons
      button {
        width: 180px;
        background: white;
        // background: transparent;
        padding: 10px 0px;
        border: 1px solid transparent;
        border-radius: 1000px;
        text-align: center;
        z-index: 1;
        position: relative;
      }

      // styling the first button
      .sign-up__btn {
        margin-right: 18px;
      }

      // styling learn more button
      .lrn-more__btn {
        background-clip: padding-box;
        z-index: 1 !important;
        box-sizing: border-box;
        color: white;
      }
      .lrn-more__btn::before {
        background: -webkit-linear-gradient(
          350deg,
          #00d2e9 -4.81%,
          #e975e8 81.46%
        );
        z-index: -1 !important;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 10px;
        border-radius: inherit;
      }
    }

    // Styling all of the subcategories of the homepage
  }
  section {
    margin: 80px auto;
    background: #23262f;
    width: 90%;
    padding: 6rem 4.25rem;
    border-radius: 20px;
    text-align: center;

    // Styling section header
    h5 {
      font-size: 0.875rem;
      // color:hsla(0, 0%, 100%, 0.2);
      color: rgba(255, 255, 255, 0.4);
    }

    h2 {
      font-size: 72px;
    }
  }

  // Styling the questions section
  section#questions {
    // section header styles
    h2 {
      background: -webkit-linear-gradient(
        350deg,
        #00d2e9 -4.81%,
        #e975e8 81.46%
      );
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  // Styling the news section
  section#news {
    background-image: url("../../assets/images/dotted-page.png");
    background: #ffffff;
    background-position: 0 0;
    background-size: cover;
    background-attachment: scroll;
    padding: 6rem 4.25rem;

    .inner-container {
      background-color: hsla(0, 0%, 100%, 0.2);

      // styling the section header
      h2 {
        font-size: 72px;
        font-weight: 500;
        color: #474c68;
        margin-top: 20px;
      }

      // styling the section sub-header
      h5 {
        color: rgba(35, 38, 47, 0.4);
      }
    }
  }
}
