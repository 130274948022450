// This file styles the footer component

#Footer {
    min-height: 200px;
    width: 100%;
    color: #333;
    background: white;
    // position: relative;
    
    // Styling the inner container
    .footer-inner-container {
        
        // Styling content-containers
        .left, .middle, .right {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center
        }
        
        // Styling the left side
        .left {
            img {
                width:min-content;
                object-fit: contain;
                width: 100%;
                height: 100%;
                overflow: hidden;
                max-width: 200px;
            }
        }
        // Styling the middle section
        .middle {
            p {
                margin: 0;
            }
            .webLink {
                a {
                    margin-left: 10px;
                }
            }
        }
    }
}
@media screen and (min-width: 801px) {

    #Footer {
        min-height: 200px;
        width: 100%;
        color: #333;
        background: white;
        // position: relative;
        
        // Styling the inner container
        .footer-inner-container {
            width: 100%;
            height: 100%!important;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: 100%;
            
            // Styling content-containers
            .left, .middle, .right {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center
            }
            
            // Styling the left side
            .left {
                img {
                    width:min-content;
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    max-width: 300px;
                }
            }
            // Styling the middle section
            .middle {
                p {
                    margin: 0;
                }
                .webLink {
                    a {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
    
    // Styling for smaller devices < 800px
    @media screen and (max-width: 800px) {
        #Footer {
            .footer-inner-container {
                display: grid;
                grid-template-areas: "info info info"
                "firma . tuas";
                
                .left {
                    grid-area: firma;
            }
            .middle {
                grid-area: info;
            }
            .right {
                grid-area: tuas
            }
        }
    }
}