// @desc: Styling the navigation component

#navigation {
  * {
    margin: 0;
    padding: 0;
    text-decoration: none;
    outline: none;
    box-shadow: none;
  }
  position: relative;
  height: 50px;
  line-height: 50px;
  width: 100%;
  //   background: green;

  .inner-nav-container {
    width: 100%;
    height: inherit;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid #e2e2e2;

    // Global icon-container
    .icon-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 48px;
      background: #efefef;
      border: 1px solid #e2e2e2;
      &:hover {
        cursor: pointer;
        background: #e0e0e0;
      }
    }
    a {
      text-decoration: none;
      color: #000000;
      height: 48px;
    }
    // Styling the left side of the nav bar
    .nav-left {
      display: flex;
      flex-direction: row;

      .Projects-icon {
        width: 100px;
        justify-content: space-around;
        padding: 0 12px;

        p {
          font-size: 0.9rem;
        }
      }
    }

    // Styling the right side of the nav bar
    .nav-right {
      display: flex;
      flex-direction: row;
      // border: 1px solid #e2e2e2;

      // Rounding the section
      .Profile-icon {
        border-radius: 3px 0 0 3px;
      }
      .LogOut-icon {
        border-radius: 0 3px 3px 0;
      }
    }
  }
}

// Styling mobile Navigation
.mobile-nav {
    a {
        text-decoration: none;
        color:#000000;
    }
    .active {
        color:rgb(36, 69, 216);
        font-weight: 600!important;
    }
  .container {
    padding: 0 20px !important;
  }
}
