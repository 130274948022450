// This file styles the questions section of the HomePage

section#questions {
  .inner-container {
    // Styling the question list
    .question-list-container {
      display: flex;
      flex-direction: column;
      max-width: 70.625rem;
      margin: 0 auto;

      // Styling an individual question
      .question-container {
        padding: 30px;
        background: #292b38;
        margin: 15px 0;
        text-align: left;
        display: grid;

        // When the container is open
        &.open {
          border-radius: 12px;
        }

        // When the container is closed
        &.closed {
          border-radius: 1000px;
        }

        // Styling the question header
        .question-header {
          font-size: 1.375rem;
          padding: 0;
          margin: 0;
        //   padding-left: 10px;
          letter-spacing: -0.03rem;
          font-weight: 600;

          // When the question is open
          &.open {
            background: -webkit-linear-gradient(
              350deg,
              #00d2e9 -4.81%,
              #e975e8 81.46%
            );
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          // When the question is closed
          &.closed {
            color: white;
          }
        }
        //   Answer content
        .answer-content {
          color: rgba(255, 255, 255, 0.6);
        //   padding-left: 10px;
          margin-top: 12px;
            text-align: justify;
        }
      }
    }
  }
}
